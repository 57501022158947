.footer {
  background-color: $hover;
  padding: 40px 72px;
  color: $white;

  @include to-tablet-sm {
    & {
      padding: 35px 20px;
      //  display: none;
    }
  }
  @include to-desktop-sm {
    & {
      padding: 35px 20px;
    }
  }
  .footer-container {
    
    display: flex;
    padding: 0;
    justify-content: space-between;

    .text-grey-ea {
      width: 242px;
      padding: 8px 0 25px;
      color: $grey-ea;
    }
    .main-links {
      display: flex;
      justify-content: space-between;
      margin: 10px 30px 0;
      @include to-tablet-sm {
        & {
          flex-direction: column;
          margin: 0;
          padding-bottom: 60px;
        }
      }
    }
    .follow-us {
      padding: 10px 0;
      @include to-tablet-sm {
        & {
          padding: 0;
        }
      }
    }
    .nav-links {
      margin-inline-end: 24px;
      @include to-tablet-lg {
        & {
          margin-inline-end: 10px;
        }
      }
      @include to-tablet-sm {
        & {
          margin-inline-end: 10px;
          width: max-content !important;
        }
      }
      &.active {
        color: $primary-color-sky;
      }
      
    }
    .app-store {
      display: flex;
      flex-direction: column;
      @media (max-width:"375px"){
        
     
        &{ flex-direction: column !important;
          a{margin-top:7px ;}
        }
       
       }
      @include to-tablet-sm {
        & {
          flex-direction: row;
          img {
            width: 148px;
          }
        }
      }
     
      @include to-desktop-sm {
        img {
          width: 148px;
        }
      }
      @media (max-width:347px){
        
       justify-content: center;
       img{
        width:130px
       }
      } 
     
    }
    @include to-tablet-sm {
      & {
        flex-direction: column;
      }
    }
 
  }
}
.line-container {
  background-color: $white;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 12px 72px;
  z-index: 222;
  color: $primary-color-dark-blue;
  @include to-tablet-sm {
    & {
      display: none;
    }
  }

  @include to-desktop-sm {
    & {
      padding: 10px 20px;
    }
  }
}