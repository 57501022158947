.nav {
  @include iphone-xs {
    position: fixed;
  }
 
  &.position-fixed {
    z-index: 1100;
  }
  
  .nav-items {
    height: 55px;
    display: flex;
    align-items: center;
    padding: 0 72px;
    box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px
      rgba(0, 0, 0, 0.05);
    @include to-tablet-sm {
      & {
        padding: 16px 20px;
        height: 64px;
      }
	  .togglejustify{
		justify-content: space-around;
	  }
      &.nav-extender {
        height: auto !important;
      }
    }
    @include to-desktop-sm {
      & {
        padding: 0 16px;
      }
    }
	@include iphone-xs{
		padding: 0 10px;
		.togglejustify{
			justify-content: space-around;
		  }
	}
    .logo {
      margin-inline-end: 21px;
      width: 130px;
      @include iphone-xs {
        width: 100px;
      }
      // margin-top: -10px;
      @include iphone-xs {
        width: 100px;
      }
      // @include to-tablet-lg {
      //   & {
      //     margin-inline-end: 21px;
      //   }
      // }
      @include to-tablet-sm {
        & {
          margin-top: 5px;
        }
      }
    }
    .mainLogo {
      width: 100px !important;
    }
    .nav-links {
      margin-inline-end: 16px;
      // @include to-desktop-sm {
      //   & {
      //     margin-inline-end: 16px;
      //   }
      // }
      @include iphone-xs {
        & {
          margin-inline-end: 0;
        }
      }
      &.active {
        color: $primary-color-sky;
      }
    }
    .search-form {
      position: relative;
      input {
        width: 280px;
        height: 42px;
        padding-inline-start: 51px;
        padding-inline-end: 15px;
        border: 1px solid $primary-color-sky;
        color: $primary-color-sky;
        border-radius: 100px;
        outline: none;
        @include to-tablet-lg {
          & {
            width: 165px;
            padding-inline-start: 41px;
            font-size: 14px;
          }
        }
        &::placeholder {
          color: $primary-color-sky;
        }
      }
      .searchPrimary {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
      }
    }
    .hamburger-icon-container {
      height: 40px;
      width: 85px;
      border-radius: 100px;
      padding: 0px 5px 0px 16px;
      justify-content: space-between;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-self: center;
      .avatar-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
      @include to-tablet-sm {
        & {
          width: 72px;
          height: 32px;
          padding: 0 8px;
          .avatar-img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
.tablet-none {
  @include to-tablet-lg {
    & {
      display: none;
    }
  }
}
.bg-main-color {
  background-color: $primary-color-sky !important;
  color: $white !important;
  padding: 4px 12px;
  border-radius: 4px;
}
.bg-dark-color {
  background-color: $primary-color-blue !important;
  color: $white !important;
  padding: 4px 12px;
  border-radius: 4px;
}

.temporery-respons {
  &.MuiButtonBase-root.MuiButton-root {
    min-width: auto;
    padding: 0;
  }
}
.min-h-auto {
  &.MuiButtonBase-root.MuiMenuItem-root {
    min-height: auto;
  }
}
.logo-x {
  margin-inline-end: 21px;
  width: 130px;
 }
.closeIcon{
  background-color: #ececec;
  border-radius: 100%;
  border: 5px solid #ececec;
  width: 32px;
  height: 32px;
  box-sizing: content-box;
  cursor: pointer;
}