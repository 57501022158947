.main-header {
  background-color: $primary-color-dark-blue;
  color: $white;
  padding: 10px 72px;
  @include to-desktop-sm {
    & {
      padding: 10px 16px;
    }
  }
}

.main-home {
  display: flex;
  justify-content: space-between;

  @include to-tablet-sm {
    & {
      display: block;
    }
  }
  &.space {
    padding: 60px 72px;
    gap: 20px;
    @include to-desktop-sm {
      & {
        padding: 30px 16px;
        gap: 16px;
      }
    }
    @include to-tablet-sm {
      & {
        gap: 0;
        padding: 30px 16px;
      }
    }
    @include iphone-xs{
      margin-top: 70px;
    }
  }

  .home-content {
    width: 60%;
    @include desktop-lg {
      // width: 856px;
    }
    @include to-tablet-lg {
      & {
        width: 100%;
      }
    }
    .home-card {
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 18px;
      background-color: $white;
      box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
      gap: 20px;
      // word-break: break-all;
      // @include to-desktop-sm {
      //   & {
      //     padding: 16px;
      //   }
      // }
     
      @include to-tablet-sm {
        & {
          // padding: 16px;
          gap: 0;
        }
      }
      @media (max-width:'425px') {
        .dir-btn-sm{
          flex-direction: column !important;
        }
      }
      &.filter-padding {
        padding: 15px 16px 16px;
        @include to-desktop-sm {
          & {
            padding: 16px;
          }
        }
        @include to-tablet-sm {
          & {
            padding: 16px 0;
            gap: 0;
          }
        }
      }
      .home-title {
        color: $primary-color-blue;
        
      }
      .mb-text {
        margin-bottom: 0.157rem;
      }
      .filters-section {
        position: absolute;
        top: 11px;
        right: 10px;
      }
      .media-card {
        position: relative;
        z-index: 1;
        border-radius: 24px;
        background-color: $white;
        border-color: transparent;
        box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
        @include to-tablet-sm {
          & {
            border-radius: 0;
          }
        }

        .card-space {
          padding: 24px 16px 0;
          margin: auto;
          @include to-desktop-sm {
            & {
              .centered {
                text-align: center;
              }
            }
          }
        }
        .border-c8 {
          border-bottom: 1px solid $grey-c8;
        }
        .establishment {
          @include to-desktop-sm {
            & {
              width: 312px;
              margin: auto;
            }
          }
        }
        .ant-row-space {
          .ant-row {
            margin: 0;
          }
        }
        .apply-filter {
          display: flex;
          justify-content: end;
          align-items: center;
          background-color: $grey-fa;
          border-top: 1px solid $grey-ea;
          border-radius: 0px 0px 24px 24px;
          padding: 16px 32px;
          box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
        }
      }
      .btn-margin {
        margin-top: -2px;
      }

      .loading-img {
        width: 120px;
        height: 120px;
        background-color: $grey-ea;
      }
      .loading-height {
        height: 24px;
        background-color: $grey-ea;
      }
      $width: 40, 20, 100, 128;
      @mixin loading-card-($width) {
        @if $width== 100 {
          width: 100%;
        } @else if $width== 40 {
          width: 40%;
        } @else if $width== 20 {
          width: 20%;
        } @else if $width== 128 {
          width: 128px !important;
        }
      }
      @each $width in $width {
        .loading-card-#{$width} {
          @include loading-card-($width);
        }
      }
    }
    .ant-row {
      width: auto;
    }
  }
  .profile-status {
    position: sticky;
    // right: 56px;
    align-self: flex-start;
    top: 85px;

    @include to-desktop-sm {
      top: 71px;
    }

    @include to-tablet-sm {
      & {
        position: relative;
        top: 0;
        right: 0;
        /* margin-top: 25px; */
      }
    }
    @include iphone-xs{
      .d-alert{
        display: flex;
        justify-content: center;
        span{
          font-size: 14px !important;
        }
       
      }
     
    }
    .btn-add {
      margin: auto;
      padding: 5px 0 !important;
      height: auto;
      background-color: $primary-color-dark-blue !important;
      color: #fff !important;
      display: flex !important;
      align-items: center;
      @include to-tablet-sm {
        & {
          display: none !important;
        }
      }
    }
    .profile-status-card {
      background-color: $white;
      width: 330px;
      margin: 0 auto 16px;
      padding: 16px 25px;

      @include to-desktop-sm {
        & {
          padding: 16px;
        }
      }
      border-radius: 4px;
      text-align: center;
      height: fit-content;
      box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
      .profile-status-icon {
        width: 24px;
        height: 24px;
      }
      @include to-tablet-sm {
        & {
          width: 100%;
          padding: 16px 51px;
          margin-bottom: 16px;
          .profile-status-icon {
            width: 20px;
            height: 20px;
          }
        }
      }
      @include iphone-xs{
        padding: 16px 21px;
        
      }
    }
    .bg-dark-blue {
      background-color: $primary-color-dark-blue;
      color: $white;
    }
  }
}
.add-position {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
}
.full{
  max-width: 100%  !important;
}
