/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

ol {
  padding-left: 16px;
}

p {
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.h-fill {
  height: -webkit-fill-available;
}

.h-input {
  height: 50px !important;
}

.word-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.uppercase {
  text-transform: uppercase;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------------------------------------------------------------- */
/*                                   placeholder                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                   inputs                                   */
/* -------------------------------------------------------------------------- */
$sizes: "xs", "sm", "md", "xmd", "lg", "xlg", "xmd-a", "lg-a";

@mixin input-size($size) {
  @if $size=="xs" {
    width: 120px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
  }

  @if $size=="sm" {
    width: 145px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
  }

  @else if $size=="md" {
    width: 196px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
  }

  @if $size=="xmd" {
    width: 300px !important;
    height: 50px;
    font-size: 14px;
    font-weight: 600;

    & {
      @media (max-width: 356px) {
        width: 270px !important;
      }

      @include iphone-xs {
        width: 250px !important;
      }

      @media (max-width: 315px) {
        width: 215px !important;
      }
    }
  }

  @else if $size=="lg" {
    width: 418px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
  }

  @else if $size=="xlg" {
    width: 418px !important;
    height: 58px !important;
    font-size: 14px;
    font-weight: 600;
  }

  @if $size=="xmd-a" {
    width: 268px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;

    & {
      @media (max-width: 356px) {
        width: 238px !important;
      }

      @include iphone-xs {
        width: 218px !important;
      }

      @media (max-width: 315px) {
        width: 183px !important;
      }
    }
  }

  @else if $size=="lg-a" {
    width: 384px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
  }
}

@each $size in $sizes {
  .input-#{$size} {
    @include input-size($size);
  }
}

.form-lg {
  //width: 400px !important;
}

.form-xmd {
 
 
  @media (max-width:"426px") {
    width: 300px !important;
  }
 
  
}

.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordion-rounded {
  border-radius: 4px !important;
  border: 1px solid #eaeaea;
  box-shadow: none !important
}

//Set the material ui input fields to border-red with red label color
.ant-form-item-has-error {
  .MuiInputBase-formControl {
    border: 1px solid red !important;
  }

  .MuiInputLabel-root {
    color: red !important;
  }
}

// .ant-form-item-explain-error{padding: 3px 0;}
.ant-form legend {
  display: none;
}

.css-ph7vok-MuiFormLabel-root-MuiInputLabel-root,
.css-1ru49kn {
  top: -3px !important;
  // background: white;
  // padding:0 5px !important;
}

/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */
$rubik-fonts: 40px, 32px, 24px, 26px, 22px, 20px, 17px, 16px, 14px, 12px, 10px;
$poppins-fonts: 36px, 32px, 24px, 20px, 17px, 18px, 16px, 14px, 13px, 12px, 10px;
$font-weights: 200, 300, 400, 500, 600, 700, 800, 900;

@mixin rubik-fonts-weight($fontSize) {
  @if ($fontSize==32px) {
    font-weight: 500;
  }

  @else {
    font-weight: 400;
  }
}

@mixin poppins-fonts-weight($fontSize) {
  @if ($fontSize==40px) {
    font-weight: 600;
  }

  @else if($fontSize==32px) {
    font-weight: 500;
  }

  @else if($fontSize==24px) {
    font-weight: 500;
  }

  @else if($fontSize==26px) {
    font-weight: 500;
  }

  @else {
    font-weight: 400;
  }
}

@each $font in $rubik-fonts {
  .f-rubik-#{$font} {
    font-size: $font !important;
    font-family: "Rubik", sans-serif !important;
    @include rubik-fonts-weight($font);
  }
}

@each $font in $poppins-fonts {
  .f-poppins-#{$font} {
    font-size: $font !important;
    font-family: "Poppins", sans-serif !important;
    @include poppins-fonts-weight($font);
  }
}

@each $weight in $font-weights {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

/* -------------------------------------------------------------------------- */
/*                                   buttons                                  */
/* -------------------------------------------------------------------------- */
@mixin btn-size($size) {
  @if $size=="xs" {
    max-width: 85px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    padding-inline: 25px;
  }

  @else if $size=="sm" {
    width: 165px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    padding-inline: 25px;
  }

  @else if $size=="md" {
    width: 195px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 0;
  }

  @else if $size=="xmd" {
    width: 300px;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 0;

    @include iphone-xs {
      width: 250px;
    }
  }

  @else if $size=="lg" {
    width: 418px;
    height: 48px;
    padding: 8px 0;
    font-weight: 600;
  }

  @else if $size=="auto" {
    width: max-content;
    height: max-content;
    padding: 8px 0;
  }
}

@mixin btn-type($type) {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 4px;

  @if $type=="primary" {
    background: $primary-color-sky;
    color: $white;
  }

  @else if $type=="primary-blue" {
    background: $primary-color-blue;
    color: $white;
  }

  @else if $type=="secondary" {
    border: 2px solid $primary-color-sky;
    color: $primary-color-sky;
    background: transparent;
  }

  @else if $type=="text" {
    color: $primary-color-sky !important;
    background: transparent;
    border: none !important;
  }

  @else if $type=="light" {
    border: 2px solid $primary-color-sky;
  }
}

@mixin btn-state($state, $type) {
  @if $type=="primary" {
    @if $state=="hover" {
      background: $hover;
      color: $white !important;
    }

    @else if $state=="disabled" {
      background: $grey-c8;
    }
  }

  @else if $type=="primary-blue" {
    @if $state=="hover" {
      background: $primary-color-blue;
      color: $white;
    }

    @else if $state=="disabled" {
      background: $primary-color-blue;
      color: $white;
    }
  }

  @else if $type=="secondary" {
    @if $state=="hover" {
      border: 2px solid $hover;
      color: $hover;
    }

    @else if $state=="disabled" {
      color: $grey-c8;
      border-color: $grey-c8;
    }
  }

  @else if $type=="text" {
    box-shadow: none;

    @if $state=="hover" {
      color: $hover;
      border: none !important;
    }

    @else if $state=="disabled" {
      color: $grey-c8;
    }

    @else if $state=="focus" {
      border: none;
      outline: none;
    }
  }
}

$btn-sizes: "xs", "sm", "md", "xmd", "lg", "auto";
$btn-types: "primary", "primary-blue", "secondary", "text", "light";

@each $size in $btn-sizes {
  @each $type in $btn-types {
    .btn-#{$type}-#{$size} {
      @include btn-size($size);
      @include btn-type($type);

      &.auto {
        width: max-content;
        padding: 10px 24px;
      }
    }

    .btn-#{$type}-#{$size}:hover {
      @include btn-state("hover", $type);
    }

    .btn-#{$type}-#{$size}:disabled {
      @include btn-state("disabled", $type);
    }

    .btn-#{$type}-#{$size}:focus {
      @include btn-state("focus", $type);
    }
  }
}

.acheivment-section {
  border-radius: 8px;
  border: 1px solid $grey-ea;
  // width: 100%;
  padding: 16px;

  &.exp-profile {
    width: 312px;

    .input-expertise {
      width: 100% !important;
      height: 50px !important;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &.activation {
    width: 418px;

    .input-expertise {
      width: 100% !important;
      height: 50px !important;
      font-size: 14px;
      font-weight: 600;
    }

    @include to-tablet-sm {
      width: 312px;
    }
  }
}

.btn-removed {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.searchBtn {
  background-color: #66ccff;
  border-radius: 100%;
  width: 40px;
  height: 38px;
}

/* -------------------------------------------------------------------------- */
/*                                    text-status                                   */
/* -------------------------------------------------------------------------- */
@mixin text-color($type) {
  @if $type=="active" {
    color: $alert-success;
  }

  @else if $type=="inactive" {
    color: $alert-fail;
  }

  @else if $type=="pending" {
    color: $alert-inprogress;
  }

  @else if $type=="rejected" {
    color: $alert-fail;
  }
}

$text-colors: "active", "pending", "inactive", "rejected";

@each $color in $text-colors {
  .text-#{$color} {
    @include text-color($color);
  }
}

/* -------------------------------------------------------------------------- */
/*                                    bg-status                                   */
/* -------------------------------------------------------------------------- */
@mixin bg-color($type) {
  width: fit-content;
  margin: auto;
  color: $white;
  padding: 2px 16px;
  border-radius: 8px;
  text-transform: uppercase;

  @if $type=="active" {
    background-color: $alert-success;
  }

  @else if $type=="inactive" {
    background-color: $alert-fail;
  }

  @else if $type=="pending" {
    background-color: $alert-inprogress;
  }

  @else if $type=="rejected" {
    background-color: $alert-fail;
  }
}

$text-colors: "active", "pending", "inactive", "rejected";

@each $color in $text-colors {
  .bg-#{$color} {
    @include bg-color($color);
  }
}

/* -------------------------------------------------------------------------- */
/*                                   states                                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                global setup                                */
/* -------------------------------------------------------------------------- */
.text-underline {
  text-decoration: underline;
}

.text-grey-54 {
  color: $grey-54;
}

.text-grey-c8 {
  color: $grey-c8;
}

.text-grey-8d {
  color: $grey-8d;
}

.text-green {
  color: $alert-success;
}

.text-blue {
  color: $primary-color-blue;
}

.flex-1 {
  flex: 1;
}

.h-52 {
  height: 52px;
}

.h-131 {
  min-height: 431px;
  max-height: 43px;
  padding: 20px 0;
}

////////////////////main-color/////////////////
.main-color {
  color: $primary-color-sky !important;
}

.main-title {
  color: $primary-color-dark-blue;
}

.text-dark {
  color: $dark-color;
}

.text-white {
  color: $white;
}

.pr {
  padding-right: 0 !important;
}

.pr-24 {
  padding-right: 24px;
}

.p-16 {
  padding-right: 16px;
}

.w-13 {
  width: 14px !important;
}

.premium {
  color: #ffc106;

  white-space: nowrap;
}

.inactive-text {
  color: rgba(200, 200, 200, 1);

  white-space: nowrap;
}

.inactive-text:hover {
  color: #66ccff;
}

.premiJob {
  border-bottom-left-radius: 22px;
  /* border-top-left-radius: 4px; */
  color: #fff;
  background-color: #ffc106;

  width: 138px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px 20px 8px 20px;

  @include to-tablet-sm {
    & {
      width: 100px;
      height: 32px;
      padding: 4px 16px 4px 16px;
      font-size: 12px;
    }

    & {
      img {
        height: 14px !important;
      }
    }
  }
}

.bg-grey-fa {
  background-color: rgba(250, 251, 251, 0.8);
  min-height: calc(100vh - 55px);

  @include to-tablet-sm {
    & {
      min-height: 100vh;
    }
  }
}

//filter-icon-section
.filter-icons-group {
  position: absolute;
  top: 20px;
  right: 16px;
}

.btn-icon {
  border: 0 !important;
  border-color: transparent;
  box-shadow: none;
  padding: 0;
  outline: 0;
  background-color: transparent;

  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
    border-color: transparent;
  }
}

.input-textArea {
  width: 418px !important;
  font-size: 14px;
  font-weight: 600;

  @include to-tablet-sm {
    & {
      width: 312px !important;
    }
  }
}

.textAreaError {
  border: 1px solid #d32f2f;
  border-radius: 8px;
}

.textEditor {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-row {
  width: fit-content;
  margin: auto;

}

.check-prem-input {

  .ant-row {
    margin-left: .5rem !important;

  }

  @include iphone-xs {
    margin-left: 1.4rem !important;
  }

}

.check-input {
  .ant-row {
    margin-left: 0 !important;

  }
}

.check-activeinput {
  .ant-row {
    margin-left: .5rem !important;
  }

  @media (max-width:'356px') {

    margin-left: 1.5rem !important;
  }
}

.check-prem {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: Poppins !important;
  color: #545454 !important;
  line-height: 16px !important;

  @include iphone-xs {
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.n-m-auto {
  .ant-row {
    width: fit-content;
    margin: 0 !important;
  }
}

.fail-text {
  color: $alert-fail;
}

.ant-form-item-explain-error {
  text-align: left;
}

.form-container {
  width: 50%;
  background: #fff;
  border-radius: 24px;
  text-align: center;
  margin: 40px auto;
  height: fit-content;
  padding: 18px;
  box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
  position: relative;

  &[full-width='true'] {
    width: 100%;
  }

  .alert-box {
    z-index: 9;
    position: absolute;
    // top: -45px;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;

    .link-create-account,
    .link-create-account:hover,
    .link-create-account>a {
      display: block;
      text-decoration: underline;
      cursor: pointer;
      color: $grey-54 !important;
      font-weight: 600;
    }
  }

  .progress-container {
    width: 418px;
    margin: auto;

    @include to-mobile-sm {
      & {
        width: 250px;
      }
    }
  }

  .notes-text {
    color: $alert-success;
    margin: 0 6px;
  }

  .alert-text {
    color: $alert-fail;
    margin: 0 6px;
  }

  // #acceptTerms {
  //   @include desktop-lg {
  //     width: 100% !important;

  //   @include to-tablet-sm  {
  //   width:90% !important;
  //  }

  //   }
  // }

  @include tablet-xs {
    & {
      width: 60%;
      margin: 20px auto;
    }
  }

  @include mobile-sm {
    & {
      width: 70%;
      margin: 20px auto;
    }
  }

  @include to-mobile-sm {
    & {
      width: 95%;
      margin: 20px auto;
    }
  }

  @include desktop-lg {
    .form-side-title {
      width: 418px;
    }
  }

  @include desktop {
    .form-side-title {
      width: 418px;
    }
  }

  @include tablet {
    .form-side-title {
      width: 418px !important;
    }
  }

  @include to-tablet-sm {
    .form-side-title {
      width: 300px !important;
    }

    // #acceptTerms {
    //   width: 85%;

    //  }
    //  #acceptTerms_help div{
    //   width: 76%;
    //   margin:auto;
    //  }
  }

  @include iphone-xs {
    .form-side-title {
      width: 250px !important;
    }

    //   #acceptTerms {
    //     width: 95%;}
  }

  @media (max-width: 315px) {
    .form-side-title {
      width: auto !important;
    }
  }

  @include iphone-xs {
    margin-top: 96px;

    .half-w {
      justify-content: center !important;
    }

    .input-sm {
      width: 120px !important;
    }
  }
}

[dir="rtl"] .arrow-back {
  transform: rotate(180deg);
  /* reverse direction, for RTL */
}

.arrow-back {
  width: min-content;
}

.textArea-height {
  height: 143px !important;
}

.react-datepicker-popper {
  z-index: 1011;
  width: 100%;
}

.react-datepicker {
  width: 100%;
  top: -4px;
  margin-top: -24px;
  z-index: 9999;
  left: 0;
  right: 0;
}

.ant-upload {
  background: rgba(102, 204, 255, 0.05);
  /* # 66CCFF */
  border-radius: 8px;
  border: 1px solid $primary-color-sky;
}

//////////Modal Styles//////////////////////
// .ant-modal-mask,.ant-modal-centered{  top: 80px !important;}
.ant-modal-centered {
  @include to-tablet-sm {
    margin-top: 7px !important;
  }
}

.top-up .ant-modal-content {

  top: 80px !important;

  @include to-tablet-sm {
    top: 100px !important;
  }
}

.top-up-more .ant-modal-content {

  top: 80px !important;

  @include to-tablet-sm {
    top: 120px !important;
  }
}

.top-con .ant-modal-content {

  top: 120px !important;

  @include to-tablet-sm {
    top: 140px !important;
  }
}

.isWidthModal {
  position: relative;

  max-width: auto !important;
  margin: 0 auto !important;

  &.ant-modal {
    padding-bottom: 0 !important;
  }

  .ant-modal-content {
    padding: 20px 24px 4px;

    // margin-bottom: 29px !important;
  }



}

.top-105 {
  top: 105px !important;
}

.top-105 {
  @include to-tablet-sm {
    & {
      top: 105px !important;
    }
  }
}

.isFullWidth {
  @include to-tablet-sm {
    & {
      max-width: 100% !important;
      width: 100% !important;
      padding-top: 73px;
      top: 0;
      padding-bottom: 0;
    }

    .ant-modal-content {
      min-height: calc(100vh - 74px);
      border-radius: 0;
      padding: 1px;
    }
  }
}

.auto-width {
  width: fit-content !important;

  .ant-modal-content {
    padding: 20px 16px;

    @include to-tablet-sm {
      padding: 16px 16px;
    }
  }
}

.fifty-width {
  width: 60% !important;

  .ant-modal-content {
    padding: 24px 16px;
  }
}

.padding-32 {
  padding: 32px;
}

////////////////////////////////////////////////////////////

/////////////////////spacing/////////////////////////////////
.top-55 {
  top: 55px;
}

.top-30px {
  top: 30px !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;

}

.top-45 {
  top: 45px;
}

.top-80px {
  top: 80px !important;
}

.top-50px {
  top: 50px !important;
}

/////////////////////////////////////////////interest page////////////////////
.interest-space {
  padding: 40px 56px;

  @include to-desktop-sm {
    & {
      padding: 16px;
    }
  }

  @include to-tablet-sm {
    & {
      padding: 16px;
    }
  }
}

.interest-button {
  display: flex;
  width: 125px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border-color: transparent;

  &.active {
    background-color: $primary-color-sky;
    color: $white;
  }
}

.border-ea {
  border-bottom: 1px solid $grey-ea;
}

.border-grey-light {
  border-bottom: 1px solid $light-grey;
}

.text-editor {
  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    height: 120px;
    border: 1px solid $grey-ea;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 9px 0;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid $grey-ea;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
  }

  .ql-snow.ql-toolbar button svg {
    opacity: 0.5;
  }

  .ql-editor.ql-blank::before {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-style: normal;
  }

  .ql-editor p,
  .ql-editor ul,
  .ql-editor ol {
    font-family: $poppins-fonts;
    font-size: 16px;
    font-weight: 400;
    color: $grey-54;
  }
}

.text-list ul li {
  list-style: inside;
  text-indent: -22px;
  margin-left: 20px;
}

.l-height {
  line-height: 2.4;

  @include to-tablet-lg {
    & {
      line-height: 1.6;
    }
  }
}

.loader-spinner {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1000;
}

.text-justify {
  text-align: left;
}

.centered-item {
  width: 100%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);

  @include to-tablet-sm {
    top: 37%;
  }
}

/* .height-centered {
  position: relative;
  min-height: calc(100vh - 120px);
  @include to-tablet-sm {
    height: calc(100vh - 77px);
  }
} */

.t_c li {
  list-style: circle !important;
  margin-left: 20px !important;
}

.t_c h2,
h3,
h4 {
  margin-top: 30px !important;
}

.aboutUsTextarea {
  .ant-form-item {
    width: 100% !important;
    min-width: 312px;
  }

  .ant-row {
    width: 100% !important;
  }
}

.textOverflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.btn-primary-lg,
.btn-primary-sm,
.btn-primary-md,
.btn-primary-xmd,
.btn-primary-xs {
  &:hover:not(:disabled) {
    background-color: $hover !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.jobtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}

.ant-form-item .ant-form-item-explain-connected {
  width: auto;
}

.p-btn {
  min-width: 162px !important;
}

.label-color {
  color: #545454;

}

.ml-3 {
  margin-right: 5px !important;
}
#menu-jobId{
  #selectItem {
    width: 421px !important;
    white-space: normal;
 
    @media (max-width:'320px') {
      width: 234px !important;
    }

    @include mobile-xs {
      width: 278px !important;
    }
  }
}

.input-chat {
  width: 421px !important;
  height: 50px !important;

  @include mobile-xs {
    width: 278px !important;
    height: 50px;
    font-size: 14px;
  }

  @media (max-width:'320px') {
    width: 234px !important;
    height: 50px;
  }
}

.searchIconHolder {
  background-color: #66ccff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #66ccff;
  cursor: pointer;

  path {
    color: white;
  }

  &[inactive='true'] {
    cursor: not-allowed;
    background-color: #c8c8c8c8;
    border: 5px solid #d5d5d5c7;

    svg {
      color: white;
    }
  }
}

.MuiButton-containedPrimary {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 12px 0 !important;

  &:hover:not(:disabled) {
    background-color: $hover !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.MuiButton-text {
  font-weight: 600 !important;
}

.MuiButton-outlined {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 12px 0 !important;
  border: 2px solid #6cf !important;

  &:hover {
    border-color: #4096ff !important;
    color: #4096ff !important;
  }
}

.min-w {
  max-width: 418px !important;

  @media (max-width:"425px") {
    max-width: 312px !important
  }
}

.error-Msg {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px;
  height: 20px;
  max-width: 418px;
}

.MuiFormHelperText-contained {
  margin: 3px !important;
}

.pressme1 {
  margin-right: 3rem;
  width: 100%;

  @include iphone-xs {
    margin-right: 0 !important;
  }
}

.pressme2 {
  margin-right: 1rem;
  width: 100%;

  @include iphone-xs {
    margin-right: 0 !important;
  }
}

.cont-buttons {

  @include iphone-xs {
    // flex-direction: column;

    justify-content: center;

    align-items: center !important;

    & div button {
      padding-inline: 8px !important;
      margin: 0 4px !important;
    }
  }
}

.radius8px{
  border-radius: 8px;
}

.links {
  @media (max-width:"420px") {
    svg {
      scale: .6;
    }
  }

  @media (max-width:"320px") {
    width: 210px;

    a {
      width: 60%;
    }

    svg {
      scale: .54444;
    }
  }
}
.input-wth{width: 300px !important;height: 65px !important;}
.flex-row{
  @media (max-width:"426px") {
    flex-direction: column !important;
  }
}
.bio-style ul li{
  list-style: inherit;
}
.bio-style ul{
  padding-left:1.5em;
}
.btn-size{
  width:165px !important;
  height: 40px !important;
}
div:has(.btn-size){
  @media (max-width:'320px') {
    justify-content: center;
    gap: 8px !important;
  }
}
.h-auto{
  height: auto !important;
}