.faq-width {
  width: 73%;
  margin: auto;
  padding: 40px 0;

  @include to-tablet-lg {
    & {
      width: 100%;
      padding: 68px 16px
    }
  }

  @include to-tablet-sm{
    & {
      padding: 20px 16px
    }
  } 
}

.mb-80 {
  margin-bottom: 80px;

  @include to-tablet-lg {
    & {
      margin-bottom: 40px;
    }
  }
}

.accordion-section {
  align-items: center;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);

  border: none !important;

  .panel-item {
    background-color: $white;
    // padding: 4px 16px;
    margin-bottom: 16px;
  }

  .text-dark {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.ant-collapse {
    .ant-collapse-item {
      border-bottom: none;
      border-radius: 8px;

      .ant-collapse-header {
        padding: 16px 32px;
        color: $primary-color-dark-blue;
      }
    }
  }
}