.imprintPage{
    padding: 72px;
    .socialsInfo{
        p{
            margin-bottom: 0.2rem;
        }
        margin-bottom: 2.5rem;
    }
    .section2{
        margin-bottom: 2.5rem;
        p{
            color: #615e5e;
        }
        span{
            color: #222;
        }
        a{
            color: $primary-color-blue;
            text-decoration: underline;
        }
    }
    .section3{
        margin-bottom: 2.5rem;
        p{
            margin-bottom: 1rem;
        }
        h2{
            margin-bottom:1.5rem ;
        }
        h3{
            font-size: 20px;
        }
    }
}