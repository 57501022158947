.doctor-nurse-card {
	padding: 20px 16px;
	margin-bottom: 20px;
	gap: 20px;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
	align-items: center;
	justify-content: space-between;
	.name {
		color: $primary-color-blue;
	}
	.location {
		color: $grey-54;
	}
	.other-specs {
		color: $grey-8d;
	}
	.delete-connection-btn {
		color: $grey-8d;
	}
	@include to-tablet-sm {
		& {
			flex-direction: column;
			padding: 16px;
			align-items: flex-start;
		}
	}
}
