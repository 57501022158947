.public-home {
  .about-header {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  .h-210 {
    height: 210px !important;

    @include to-tablet-lg {
      height: 100%;
    }
  }
  .h-270 {
    height: 270px !important;

    @include to-tablet-lg {
      height: 100%;
    }
  }
  .h-300 {
    height: 300px !important;

    @include to-tablet-lg {
      height: 100%;
    }
  }

  .strength-card {
    @include to-tablet-lg {
      padding: 24px 28px;
    }
  }

  .about-bio {
    background-color: $primary-color-dark-blue;
    color: $white;
    padding: 35px 55px;
    text-align: center;
    line-height: 32px;

    @include to-desktop-sm {
      & {
        padding: 20px 21px;
      }
    }

    @include to-tablet-lg {
      & {
        padding: 24px 21px;
      }
    }

  }

  .social-links {
    width: 35px;
    height: 35px;

    @include to-tablet-lg {
      & {
        width: 32px;
        height: 32px;
      }
    }
  }

  .social-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      width: 35px;
      height: 35px;
      background-color: #006a9f;
      border-radius: 5px;
    }

    svg {
      position: relative;
      z-index: 1;
      width: 25px;
      height: 25px;

      path {
        color: white;
      }
    }
  }

  .social-icon-ln {
    position: relative;
    width: 35px;
    height: 35px;
    background-color: #006a9f;
    border-radius: 5px;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 0;
      width: 25px;
      height: 25px;
      background-color: white;
    }

    svg {
      position: relative;
      z-index: 1;
      background-color: transparent;

      path {
        color: #006a9f;
      }
    }
  }

  /* gate free card */
  .gate-free-container {
    background-color: $primary-color-dark-blue ;
    position: relative;

    .corner {
      width: 0;
      height: 0;
      border-top: 117px solid $alert-inprogress;
      border-bottom: 117px solid transparent;
      border-right: 117px solid transparent;
      position: absolute;
      left: 0;

      span {
        position: absolute;
        top: -100px;
        width: 60px;
        right: -65px;
        text-align: center;
        transform: rotate(-45deg);
        display: block;
        line-height: 23.7px !important;
      }
    }

    .content-container{
      width: 62.5%;
      margin: auto;
      padding-block: 48px;
      color: #fff;
      text-align: center;
      h3{
        margin-bottom: 32px;
      }
      p{
        color: #FAFBFB;
        margin-bottom: 32px;
        span{
          margin-inline-end: 16px;
        }
      }
      .btn-primary-lg:hover{
        background: $primary-color-sky !important;
        opacity: 0.9;
      }
    }

  }

  .note-section-contianer{
    background-color: #FAFBFB;
    padding-block: 70px;
    margin-bottom: 70px;
    .content-container{
      width: 80%;
      margin: auto;
      text-align: left;
      color: #545454;
    }
  }

}