.App{
	overflow-x:clip;
}
.settings-pages {
	width: 994px;
	margin: 60px auto;
	@include to-tablet-lg {
		& {
			width: 750px;
			margin: 30px auto;
		}
	}
	@include to-tablet-sm {
		& {
			width: 450px;
			margin: 30px auto;
		}
	}
	@include to-mobile-sm {
		& {
			width: 348px;
			margin: 30px auto;
		}
	}
	@media (max-width:346px) {
		
		     width: auto;
	   }
	@include iphone-xs{
	     &{	
			width: auto;
			margin: 100px auto;
			margin-bottom:30px;
		}
	}
	.forget-space{
		justify-content: flex-start;
		@include to-mobile-sm {
		& {
			justify-content: flex-end;
		}
	}
	}
	.ant-form-item-with-help ,.m-2{
		margin-left: 0 !important;
	}
}