.job-tabs {
  .tab-capsule {
    width: max-content;
    white-space: nowrap;
    height: 35px;
    border-radius: 48px;
    background: #fff;
    padding: 0px 16px;
    gap: 8px;
    color: #545454;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);

    &.active {
      background: #66ccff;
      color: #fff;
    }
    // @include to-tablet-sm {
    //   height: 35px;
    // }
  }
}
