.choose-job {
	.form-container {
		padding: 72px 24px;
		width:60%;
		@include to-tablet-lg {
			& {
				
				width:75%
			}
		}
		@include to-tablet-sm {
			& {
				
				width:93%;

			}
		}
		@include to-mobile-sm {
			& {
				
				padding: 40px 20px;
			}
		}
		@include iphone-xs{
			margin-top: 100px;
		}
		.main-title {
			color: $primary-color-dark-blue;
		}
		.user-container {
			width: 200px;
			height: 200px;
			border-radius: 50%;
			background: #fafbfb;
			padding: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			border: 0.5px solid $grey-ea;
			box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);
			&:hover {
				background: rgba(102, 204, 255, 0.15);
				border: 2px solid $primary-color-sky;
				box-sizing: border-box;
			}
			// @include to-tablet-sm {margin :0 8px;}
			@include to-mobile-sm {
				& {
					width: 159px;
					height: 160px;
					margin :0 4px;
					// #job-icon {
					// 	height: 37.8409423828125px;
					// 	width: 31.451339721679688px;
					// }
				}
			}
			
		}
	}
	// @include iphone-xs{
	// 	.user-container-1{
	// 		width: 70% !important;
	// 	}
	// 	.user-container-2{
	// 		width: 44% !important;
	// 	}
	// }
	
}
