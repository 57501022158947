/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */

$primary-color-sky: #66ccff;
$primary-color-dark-blue: #122738;
$primary-color-blue: #006a9f;
$blue-eef: #EEF9FF;
$grey-54: #545454;
$grey-8d: #8D8D8D;
$grey-aa: #aaaaaa;
$grey-c8: #C8C8C8;
$grey-ce: #cecece;
$grey-ea: #eaeaea;
$grey-fa: #FAFBFB;
$light-grey:#f5f6f7;
$hover: #122738;
$white: #fff;
$dark-color:#28293D;
$alert-success: #20c997;
$alert-fail: #dc3545;
$alert-inprogress: #ffc107;
$shadow-0-4: 0px 0px 4px 0px #0000000d;
$shadow-4-32: 0px 4px 32px 0px #0000000d;

/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
