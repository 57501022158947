.package-container {
	width: 100%;
	margin: auto;
	padding: 40px 40px;
	border-radius: 24px;
	box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px rgba(0, 0, 0, 0.05);
	background-color: $white;


	@include to-tablet-lg {
		& {
			width: auto;
			margin: 16px auto;
			padding: 16px;
		}
	}
         @media (max-width:'767px') {
			
		 
		& {
			max-width: 348px;
			margin: 16px auto;
			border-radius: none;
			box-shadow: none;
			background-color: transparent;
			padding: 0;
		}
	}

	.filterContainer {
		display: flex;
		padding: 16px;
		justify-content: center;
		align-items: center;
		gap: 16px;
		border-radius: 40px;
		background: #FFF;
		width: fit-content;
		margin: auto;
		margin-bottom: 24px;
		/* Shadow 1 */
		box-shadow: 0px 1.888px 15.106px 0px rgba(0, 0, 0, 0.05);

		.option {
			color: #848199;
			cursor: pointer;
			text-align: center;
		}

		.selected {
			background-color: $primary-color-sky;
			color: #fff;
			border-radius: 22px;
			padding: 8px 22px;
			text-align: center;
			font-weight: 600 !important;
		}

	}

	/* offcanvas design */
}

.allPackagesContainer {
	min-height: 50vh;
}

.offcanvas,
.package-container {
	.package-box {
		padding: 38px 20px 32px;
		display: flex;
		height: fit-content;
		flex-direction: column;
		overflow: hidden;
		justify-content: center;
		align-items: flex-start;
		box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
		transition: all 0.7s ease;
		background-color: $white;
		color: $grey-54;

		.packageTitle {
			text-overflow: ellipsis;
		}

		.featureContainer {

			overflow: auto;
			width: 100%;

			.featureWord {
				max-width: 95%;
				text-overflow: ellipsis;
			}

			/* width */
			&::-webkit-scrollbar {
				width: 5px;
				height: 8px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #888;
				border-radius: 8px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}

		}

		.featuresTitle {
			text-transform: capitalize;
			color: $hover;
			line-height: 16px;
			margin-bottom: 10px;
		}
	}

	.mostPopularPackage {
		padding-top: 10px;
		color: #fff !important;
		background-color: $hover;
		//background-image: url('/public/images/spiderBg.png');
		background-position: right;
		background-repeat: no-repeat;

		.featuresTitle {
			color: #fff;
		}

		img {
			filter: invert(100%) sepia(50%) saturate(2%) hue-rotate(236deg) brightness(117%) contrast(100%);
		}

		.mostPopularBadge {
			background-color: #FFC107;
			border-radius: 20px;
			padding: 6px 17px;
			text-transform: uppercase;
			line-height: 15px;
			letter-spacing: 0.83px;
		}
	}
}

.offcanvas {
	z-index: 1200;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	box-shadow: 0px 1.888px 15.106px 0px rgba(0, 0, 0, 0.05);
	background-color: #FAFBFB;
}

.offcanvas-backdrop.show {
	opacity: 0.3;
}

.offcanvas.offcanvas-end {
	width: 400px;
	border-left: 0;
	padding: 20px 16px;

	@include mobile-sm {
		width: 95%;
	}
}

.offcanvas-body {

	/* width */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 8px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

}

.package-btn {
	width: 100%;
	height: 42px;
	font-size: 14px;
	font-weight: 600;
	padding: 4px 0;
	background-color: $primary-color-sky;
	color: $white !important;
	border-color: transparent !important;

	&.ant-btn-default {
		&:hover:not(:disabled) {
			background-color: $hover !important;
		}

		&:disabled {
			color: rgba(0, 0, 0, 0.25) !important;
		}

	}
}

.packageDetailsModal {
	.detailsPackage {
		border-radius: 20px;
		box-shadow: 0px 1.888px 15.106px 0px rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: column;
		padding: 20px;
	}
}