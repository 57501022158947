.mb-32 {
  margin-bottom: 32px;
  @include to-tablet-sm {
    & {
      margin-bottom: 24px;
    }
  }
}
.mb-40 {
  margin-bottom: 40px;
  @include to-tablet-lg {
    & {
      margin-bottom: 24px;
    }
  }
}
.pb-53 {
   padding-bottom: 53px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
  @include to-tablet-sm {
    & {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
.w-72 {
  width: 72%;
   @include to-tablet-sm {
    & {
     width: 100%;
    }
  }
}

.h-0{
  height: 0;
}
.mx-home-modal{
  margin-right: 1.9rem !important;
    margin-left: 1.9rem !important;
}
.w-299 {
  width: 299px;
  margin: 16px auto;
}
.pt-80 {
  padding-top: 80px;
}
.ps-20 {
  padding-left: 20px;
}
.top-16 {
  top: 16px !important;
}
.center-translate {
  position: absolute;
  width: 100%;
  top: 43%;
  left: 50%;
  transform: translate(-50%);
}
.padding-81 {
  padding: 81px 0 0;
}
