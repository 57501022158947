.temporary-drawer {
	.hamburger-menu-header {
		box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px
			rgba(0, 0, 0, 0.05);
		padding: 26px 21px;
	}
	.hamburger-menu-body {
		padding: 21px;
	}
}
