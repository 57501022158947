.chatsLayout {
  padding: 30px 72px;

  @include to-tablet-lg {
    padding: 30px;
  }

  .chats {
    height: 77vh;
    background-color: #fff;
    box-shadow: 0px 1.8882px 15.1056px rgba(22, 20, 20, 0.05);
    border-radius: 8px;

    .allConversations {
      height: 100%;
      width: 45%;
      border-right: 1px solid $grey-ea;
      display: flex;
      flex-direction: column;

      .titleAndFilter {
        padding: 24px 8px;
      }

      .orangeCircle {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #ffc107;
        border-radius: 100%;
        align-self: flex-start;
      }

      .unreadChats {
        color: $grey-54;
      }
      .filterContainerWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        :first-child{
          width: 100%;
        }
        :last-child{
          min-width: max-content;
        }
        .filterContainer {
          border-radius: 100px;
          border: 1px solid $grey-ea;
          // padding: 8px;
          display: flex;
          align-items: center;
          .searchInput {
            width: 85%;
            border: 0;
            outline: 0;
            padding-inline: 16px;
            color: $grey-54 !important;

            &::placeholder {
              color: $grey-54 !important;
              text-transform: capitalize;
            }
          }
        }
      }
      .conversationsContainer {
        border-top: 1px solid $grey-ea;
        flex-grow: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e9e9e9 !important;
          border-radius: 15px !important;
        }
      }
    }

    .msgsBox {
      width: -webkit-fill-available;
      height: 100%;

      .selectChat {
        p {
          color: $grey-54;
        }
      }

      .inboxContainer {
        display: flex;
        flex-direction: column;

        .titleOfInbox {
          padding: 24px;

          .username {
            color: $hover;
          }
        }

        /* all msgs */
        .allMsgs {
          background-color: #fafbfb;
          padding: 24px;
          border-block: 1px solid $grey-ea;
          flex-grow: 1;
          overflow-y: scroll;
          flex-grow: 1;
          overflow-anchor: auto;

          &::-webkit-scrollbar {
            width: 0.5rem;
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #e9e9e9 !important;
            border-radius: 15px !important;
          }

          .msgDetails {
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;

            .text {
              min-width: 28%;
              width: -moz-fit-content;
              width: fit-content;
              border-radius: 16px;
              padding: 17px 22px;
              position: relative;

              @include mobile-xs {
                min-width: 35%;
                max-width: 75%;
              }

              /* &:after {
                            top: 100%;
                            left: 100%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            transform: translateX(10px);
                            transform: rotate(-90deg) translateX(21px);
                            position: absolute;
                            pointer-events: none;
                            
                            border-width: 11px;
                        } */
            }
          }

          .myMsg {
            align-items: flex-end;

            .text {
              background-color: $primary-color-dark-blue;
              color: #ffffff;
              border-bottom-right-radius: 0;

              /* &::after{
                            border-top-color: $primary-color-dark-blue;
                        } */
            }
          }

          .otherMsg {
            .text {
              background-color: #d9f2ff;
              color: $primary-color-dark-blue;
              border-bottom-left-radius: 0;
            }
          }
        }

        /* send msg */
        .writeMsgContainer {
          padding: 15px;
          gap: 16px;

          .writeMsgInput {
            flex-grow: 1;
            border-radius: 4px;
            border: 1px solid #c7c9d9;
            padding: 16px 25px;

            @include mobile-xs {
              padding: 8px 12.5px;
            }

            &::placeholder {
              color: $grey-8d;
            }

            outline: 0 !important;
          }

          .sendMsgBtn {
            border: none !important;
            background-color: $primary-color-sky;
            border-radius: 4px;
            color: #ffffff;
            padding: 16px 20px;

            @include mobile-xs {
              padding: 8px 10px;
            }

            &:disabled {
              background-color: $grey-c8;
            }

            span {
              margin-inline-start: 8px;
              font-weight: 500;

              @include mobile-xs {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.titleOfSection h1 {
  font-size: 14px;
  position: relative;
  top: 0px;
  left: 5px;
}

/* Components */
/* conversation content */
.conversationContent {
  padding: 16px 24px;
  border-bottom: 1px solid $grey-ea;

  .name {
    color: $primary-color-dark-blue;

    .title {
      color: $primary-color-blue;
    }
  }

  .msgContent {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    overflow: hidden;
    color: $grey-54;
  }

  .date {
    color: $grey-8d;
    white-space: nowrap;
  }
}

.unreadMsg {
  background-color: #eef9ff;
}