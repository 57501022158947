.conversationContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.conversationDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.conversationDetails .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.conversationDetails .title,
.conversationDetails .msgContent {
  color: #006A9F;
  font-weight: 400;
  font-size: 14px;
}

.conversationStats {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}

.conversationStats .unreadCount {
  color: "#006a9f";
  font-weight: bold;
}

.conversationStats .date {
  font-size: 12px;
}

@media (max-width: 768px) {
  .conversationDetails .name {
    max-width: 100px;
  }

  .conversationStats {
    margin-top: 0;
  }
}