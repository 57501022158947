.my-hires-pages {
  // width: 856px;
  min-height: calc(100vh - 125px);
  position: relative;
  padding: 60px 72px;
  gap: 20px;
  @include to-desktop-sm {
    & {
      padding: 16px;
      gap: 16px;
    }
  }
  @include to-tablet-sm {
    & {
      gap: 0;
      padding: 20px 16px;
    }
  }
  @include iphone-xs {
    margin-top: 87px;
  }
  @media (max-width: 355px) {
    .job-tabs {
      flex-direction: column;
    }
    .inner-tabs {
      flex-direction: row !important;
      justify-content: center !important;
    }
    .job-tabs .tab-capsule {
      width: auto;
      margin-top: 10px;
    }
  }
  .no-posts {
    .title {
      color: #006a9f;
    }
    padding: 24px 16px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include to-mobile-sm {
      & {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
	@media (max-width :465px){
		.g-tabs{flex-direction: column;
		justify-content: center;
        align-items: center;
		
	}
	.job-tabs .tab-capsule {
		width: 100%;
		margin-top: 10px;
	  }
	  .inner-tabs {
		flex-direction: row !important;
		justify-content: center !important;
	  }
	
  }
}
