.signIn-page {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 12px;
}

@media (max-width: "367px") {
  .tab {
    font-size: 12px;
  }
}
#acceptTerms_help {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
#postal_code_help,
#country_help,
#region_help {
  @include desktop-lg {
    width: 196px !important;
  }
 

  @include to-tablet-sm {
    width: 145px !important;
    
  }
  @include iphone-xs {
    width: 120px !important;
  }
}
.act-hospital #postal_code_help,#region_help,#postal_code_help,#country_help{
  width: auto !important;
}

// div :has(.ant-form-item-explain-error) {
//   @include to-tablet-sm {
//     justify-content: center !important;
//   }
// }
.edit-profile ,.profile,.act-hospital,.settings-pages,.edit-main-form div :has(.ant-form-item-explain-error){
  @include to-tablet-sm {
    justify-content: start !important;
  }
}
@include iphone-xs {
  .head-title {
    flex-wrap: nowrap !important;
  }
}
.sm-error .css-1wc848c-MuiFormHelperText-root.Mui-error{
  @include to-tablet-sm {
  font-size: 9px !important;
  font-weight: bold;
  }
}
