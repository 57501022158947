.job-card {
	margin: 10px 0;
	padding:16px;
	border-radius: 8px;
	box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.dot {
		width: 3px;
		height: 3px;
		border-radius: 50%;
		background: #000;
	}
	.closed-flag {
		right: 0;
		border-top-left-radius:30px ;
		border-bottom-left-radius:30px ;
		font-size: 13px;
	}
	.title {
		color: #006a9f;
	}
	.type {
		color: #8d8d8d;
	}
	.from-date {
		color: #c8c8c8;
	}
	.rest-data {
		color: #8d8d8d;
	}
	.cardTitle{
		max-width: 65%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	@include to-mobile-sm {
		& {
			padding: 16px;
		}
	}
}
