.fixed-line {
	background-color: $primary-color-dark-blue;
	color: $white;
	padding: 24px 205px;
	width: 100%;

	width: 100%;

	@include to-desktop-lg {
		& {
			padding: 16px 150px;
		}
	}

	@include to-desktop-sm {
		& {
			padding: 16px 50px;
		}
	}
	@media (max-width:'400px') {
		
	
		flex-direction: column;
	}

	@include to-tablet-lg {
		& {
			padding: 16px;
		}
	}
}

.contact-wrapper {
	padding: 50px 107px;
	background-color: $white;
	display: flex;
	justify-content: space-between;
	gap: 30px;
	flex: 1 1;

	@include to-desktop-sm {
		& {
			flex-direction: column-reverse;
			padding: 50px 24px 21px;
		}
	}

	@include to-tablet-sm {
		& {
			padding: 24px 0;
			background-color: rgba(250, 251, 251, 0.8);
			justify-content: center;
		}
	}

	.map-section {
		@include to-desktop-sm {
			width: 90dvw;
			height: 400px;
			flex: none;
			margin: 0 auto;
			text-align: center;
		}
    z-index: 0;
		flex: 1;

		.leaflet-container {
			height: 100%;
			width: 100%;
			border-radius: 1rem;
		}

		.leaflet-control-attribution {
			display: none !important;
		}
	}

	.form-wrapper {
		.mb-25 {
			margin-bottom: 25px;
		}

		@include to-desktop-sm {
			margin-top: 40px;
			text-align: center;
		}

		@include from-tablet-lg {
			.ant-row {
				margin: 0;
			}
		}

		@include to-tablet-sm {
			& {
				background-color: $white;
				box-shadow: 0px 1.8882px 15.10559px 0px rgba(0, 0, 0, 0.05);
				text-align: center;
				// width: 100%;
				margin: 0 16px;
				padding: 24px 10px;
				border-radius: 8px;
				margin-top: 40px;
			}
		}
	}

	.form-width {
		width: 648px;

		@include to-tablet-lg {
			& {
				width: 312px;
				margin: auto;
			}
		}

		@include iphone-xs {
			width: 255px;
		}
	}

	.title {
		@include to-tablet-sm {
			& {
				text-align: center;
			}
		}
	}

	.map-container {
		margin-top: 25px;
		width: 100%;

		iframe {
			width: 472px;
			height: 381px;

			@include to-desktop-lg {
				& {
					width: 372px;
				}
			}

			@include desktop {
				& {
					width: calc(100svw - 40px);
				}
			}

			@include to-tablet-sm {
				& {
					width: 100%;
					margin: auto;
				}
			}
		}
	}
}

.p-fixed {
	position: -webkit-sticky;
	position: sticky;
	top: 55px;
	z-index: 2;
	transition: top 0.7s;


	@include to-tablet-sm {
		& {
			top: 64px;
		}
	}
}

.fixed-action {
	padding-top: 50px;
	transition: margin 0.3s;

	@include mobile-sm {
		& {
			padding-top: 120px;
		}
	}
}

.flex-column-popup {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.range-picker {
	width: 90%;
	margin: 0 30px 30px;
	display: flex;
	flex-direction: column;
	gap: 30px;

	.ant-picker {
		width: 100%;
	}
}

.ant-picker-footer {
	display: none;
}

.ant-picker-panel-layout {
	.ant-picker-header {
		height: 30px;

		.ant-picker-header-view {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		button {
			line-height: normal;
		}
	}

	.ant-picker-content {
		height: 194px !important;

		td {
			padding: 1px 0px !important;
		}

		thead {
			th {
				height: 20px !important;
			}
		}
	}

}
.selectreason .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select{
	height: 17px !important;
	  min-height: 0 !important;
}
.row-md{
	@media (max-width:'768px') {
		display: flex;
		width: 717px;
		.form-width{width: 100% !important;}
	}
	@media (max-width:'426px') {
		flex-direction: column !important;
		width: -webkit-fill-available !important;
	}
}