
.react-datepicker-popper{
	position: relative;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: block;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__header {
  background-color: rgba(102, 204, 255, 40%);
  border-bottom: 1px solid rgba(102, 204, 255, 40%);
}
.react-datepicker__navigation-icon {
  top: 3px;
}

