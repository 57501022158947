.notifications-page {
	width: 856px;
	margin: 30px auto;
	@include to-tablet-lg {
		& {
			// width: 450px;
			width: 60%;
		}
	}
	@include to-mobile-sm {
		& {
			width: 85%;
			margin: 20px auto;
		}
	}
	@include iphone-xs{
		margin-top: 100px;
	  }
	.notification-card {
		width: 100%;
		padding: 16px;
		border-radius: 8px;
		// gap: 40px;
		box-shadow: 0px 0px 8px 0px #0000000d;
		background: #fff;
		&:hover {
			background: #eef9ff;
		}
		.title {
			color: #122738;
		}
		.description {
			color: #545454;
		}
		.createdAt {
			color: #545454;
		}
	}
}
