
.main-home {
  &.applicants {
    @include to-tablet-sm {
      flex-direction: column-reverse;
      .profile-status {
        .job-card {
          width: 100%;
        }
        width: 450px;
        margin: 5px auto;
      }
    }
    @include to-mobile-sm {
      flex-direction: column-reverse;
      .profile-status {
        .job-card {
          width: 100%;
        }
        width: 348px;
        margin: 5px auto;
      }
    }
   
  }
  @include iphone-xs{
    margin-top: 86px;
  }
  &.profile-space {
    padding: 60px 72px;
    gap: 20px;
    @include to-desktop-sm {
      & {
        padding: 30px 0px;
      }
    }
    @include to-tablet-lg {
      & {
        padding: 30px 16px;
      }
    }
    @include iphone-xs {
      margin-top: 87px;
    }
  }
}

.profile {
  .profile-content {
    padding: 24px;
    background-color: $white;
    box-shadow: 0px 1.8882px 15.1056px rgba(22, 20, 20, 0.05);
    border-radius: 8px;

    @include to-desktop-sm {
      & {
        padding: 16px 20px;
      }
    }

    @include to-tablet-sm {
      & {
        padding: 16px 20px;
        border-radius: 0;
      }
    }

    .skill-box {
      padding: 10px 4px;
      border-radius: 4px;
      background-color: $grey-ea;
      color: $grey-8d;
      margin-right: 8px;
    }
  }
  .profile-sub-card {
    padding-bottom: 24px;
    overflow: hidden;
    @include to-tablet-sm {
      & {
        padding-bottom: 16px;
      }
    }
  }
  .business_license_name {
    max-width: 170px !important;
  }
  .avatar-container {
    .avatar-img {
      border-radius: 50%;
      border: 4px solid $primary-color-sky;
      box-sizing: border-box;
      width: 128px;
      height: 128px;
      object-fit: cover;
    }
  }
  .ant-upload-list {
    display: none;
  }

  .btn-add-w {
    min-width: 195px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $white !important;
    background: $primary-color-sky !important;
    border-color: $primary-color-sky !important;
    @include to-tablet-sm {
      & {
        padding: 10px 24px;
        width: auto;
      }
    }
    @include iphone-xs {
      padding: 0 10px;
    }
  }
  .btn-w-120 {
    width: 120px;
    img {
      width: 18px;
      margin-top: -3px;
    }
  }
  .icon-btn {
    height: 0 !important;
  }

  .b-bottom {
    border-bottom: 1px solid $grey-ea;
  }

  .mb-40 {
    margin-bottom: 40px;
    @include to-tablet-sm {
      & {
        margin-bottom: 16px;
      }
    }
  }
  .mt-32 {
    margin-top: 32px;
    @include to-tablet-sm {
      & {
        margin-top: 16px;
      }
    }
  }
  .me-40 {
    margin-right: 40px;
    @include to-tablet-lg {
      & {
        margin-right: 24px;
      }
    }
  }
  .skills {
    .ant-row {
      width: 100%;
    }
  }

  .w-217 {
    width: 217px;
    height: 50px !important;
    @media (max-width:'769px') and (min-width:'590px') {
      width: 172px !important;

    }
    @media (max-width:'1024px') and (min-width:'768px') {
      width: 178px !important;}
      
      @media (min-width:'1700px') {
        width: 200px !important;
      }

  } 
  .w-172 {
    width: 172px;
    height: 50px !important;
  }
  .edit-profile {
    width: 60%;
    margin: 60px auto;
    border-radius: 8px;
    @include to-desktop-sm {
      & {
        margin: 30px auto;
      }
    }
    @include to-tablet-lg {
      & {
        width: 95%;
      }
    }
  }
  .btn-ghost {
    background-color: transparent !important;
    color: $primary-color-sky !important;
    border: 2px solid $primary-color-sky;
  }
  .ant-row {
    margin: 0;
    @include to-tablet-sm {
      width: auto;
    }
  }
  
  .side-menu {
    position: sticky;

    align-self: flex-start;
    top: 84px;
    background-color: $white;
    width: 330px;
    border-radius: 8px;
    text-align: center;
    height: fit-content;
    box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);

    @include to-tablet-sm {
      & {
        position: relative;
        top: 0;
        right: 0;
        width: auto;
        border-radius: 0;
      
      }
     
    }
   
    .ant-menu-item:not(:first-of-type) {
      border-top: 1px solid $grey-c8 !important;
    }
    .ant-menu-item {
      padding: 30px 24px;
      height: auto;
      line-height: 0;
      width: auto;
      margin: 0;
      border-radius: 0;
      color: $grey-54;
      &:hover {
        background-color: white !important;
        color: $primary-color-sky !important;
      }
    }
    .ant-menu-title-content {
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 20px;
      @include to-tablet-lg {
        & {
          font-size: 18px;
        }
      }
      @include to-tablet-sm {
        & {
          color: $white !important;
        }
      }
    }
    .ant-menu-item-selected {
      background-color: $white !important;
      border-right: 4px solid $primary-color-sky;
      color: $primary-color-sky;
    }
  }
}

.bg-white {
  background-color: $white;
  padding: 24px;
  box-shadow: 0px 1.8882px 15.1056px rgba(22, 20, 20, 0.05);
  @include to-desktop-sm {
    & {
      padding: 20px 16px;
    }
  }
}
.wraper-form{
  @include iphone-xs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
  
  }
}
$colors: "success", "warn", "danger";
@mixin text-color($color) {
  @if $color== "success" {
    padding: 8px;
    border-radius: 4px;
    background-color: $alert-success;
    color: $white !important;
  } @else if $color== "warn" {
    padding: 8px;
    border-radius: 4px;
    background-color: $alert-inprogress;
    color: $white;
  } @else if $color== "danger" {
    padding: 8px;
    border-radius: 4px;
    background-color: $alert-fail;
    color: $white !important;
  }
}
@each $color in $colors {
  .text-#{$color} {
    @include text-color($color);
  }
}

.ant-upload {
  border: 0;
}
.item-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $grey-fa;
  border: 1px solid $grey-ea;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 16px;
  @include to-tablet-sm {
    & {
      margin-bottom: 10px;
    }
  }
}
.menu-content {
  @include to-tablet-sm {
    width: 100%;
    background: $primary-color-dark-blue !important;
    color: $white !important;
    position: relative;
    z-index: 9999;
  }
}
.section-responsive {
  @include to-tablet-lg {
    justify-content: center;
    align-items: center;
  }
}

:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: $white !important;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-submenu-title:active {
  color: $white !important;
  background: $primary-color-dark-blue;
  border-radius: 0;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup {
  width: 100%;
  background: $primary-color-dark-blue;
  color: $white !important;
  z-index: 1001;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu
  .ant-menu-submenu-expand-icon,
:where(.css-dev-only-do-not-override-15rg2km).ant-menu .ant-menu-submenu-arrow {
  inset-inline-end: 0;
  inset-inline: auto;
  margin-left: 10px;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light.ant-menu-submenu
  > .ant-menu {
  border-radius: 0;
  text-align: center;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup
  .ant-menu-vertical
  .ant-menu-item:last-child {
  height: 56px;
  line-height: 56px;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup
  .ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
  padding-top: 40px;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup
  .ant-menu-vertical
  .ant-menu-item,
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup
  .ant-menu-vertical
  .ant-menu-submenu-title {
  margin-inline: 0;
  margin-block: 0;
  height: 56px;
  line-height: 56px;
  background-color: transparent !important;
  border-top: 1px solid $grey-c8;
}
.ant-menu-title-content {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 20px;
}
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-submenu-popup
  .ant-menu-vertical
  .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

  // .ant-modal{
    
  //  width: fit-content !important;
  //   margin: auto;
  //   // transform-origin: -39px 87px !important;

  // }
  // .ant-modal-content{
  //   top: 80px !important;
  // }
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  color: $primary-color-sky;
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
  background-color: rgb(18, 39, 56) !important;
  // border: 1px solid rgb(18, 39, 56) !important;
}

// ::after.ant-zoom-big-leave.ant-zoom-big-leave-active {
//  animation: none !important;
// }

// :where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-submenu-popup.ant-menu-submenu {
//   background: rgba(0, 0, 0, 0.88) !important;
// }
// :where(.css-dev-only-do-not-override-1ae8k9u).ant-zoom-big-leave {
//   animation: none !important
// }

// :where(.css-dev-only-do-not-override-1ae8k9u).ant-zoom-big-leave {
//   animation :none !important;
// }
.mb-55 {
  margin-bottom: 55px;
}
.pb-65 {
  padding-bottom: 65px;
  @include to-tablet-sm {
    & {
      padding-bottom: 16px;
    }
  }
}
.mb-65 {
  margin-bottom: 65px;
  @include to-tablet-sm {
    & {
      margin-bottom: 16px;
    }
  }
}
.mb-77 {
  margin-bottom: 77px;
  @include to-tablet-sm {
    & {
      margin-bottom: 16px;
    }
  }
}
.padding-all-profile {
  padding: 40px 56px;
  @include to-desktop-sm {
    & {
      padding: 20px 16px;
    }
  }
}
.btn-close {
  position: absolute;
  top: -10px;
  right: 0;
}
.bio {
  width: 90%;
  word-wrap: break-word;
  padding-right: 30px;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  position: fixed !important;
  top: 128px;
  width: auto;
  left: 20px;
  right: 20px;
}

.pointer {
  cursor: pointer;
}
// .add-model{
//   top:0 !important;
// }
  // .edit-input{
  
  //   @media ((max-width:'1660px') and (min-width:"1550px" )){
      
    
  //     &{
  //       margin-right: 0 !important;
  //     }
  //   }
  // }

.edit-main-form{
  .me-md-5{
  margin-right: 0.5rem !important;
  @include to-tablet-sm {
    &{
      margin-right: 3rem !important;
    }
  }
}
}
.hospital-edit input-xmd{
  height: 54px !important;
  @media (max-width:'425px') {
    flex-direction: column !important;
  }
}
.hospital-edit {
 
  @media (max-width:'425px') {
    flex-direction: column !important;
  }
}

  .input-editProfile{
    width: 312px !important;
    height: 50px !important;
    font-size: 14px;
    font-weight: 600;
    @media (min-width:'1024px') and (max-width:'1440px') {
      width: 312px !important;}
      @media (max-width:'1025px') and (min-width:'768px') {
        width: 260px !important;}
     
    @media (min-width:'1700px') {
      width: 420px !important;
    }
    @media (max-width:'320px') {
      width:220px !important
    }
  
  }
  @media (min-width:'1900px') {
    .half-w{
      width: 400px !important;
      
    }
  }