@include iphone-xs{
  .public-home{
    margin-top: 63px ;
  }
}
.public-home-bg {
  background-size: cover;
  .landing-page {
    height: 100vh;
    position: relative;
    @include to-tablet-sm {
      & {
        height: calc(100vh - 90px);
      }
    }
    .w-567 {
      width: 567px;
      @include to-tablet-sm {
        & {
          width: 90%;
        }
      }
    }
    .arrow-down-icon {
      border-radius: 100%;
      border: 2px solid white;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 80%;
      left: 50%;
      width: 55px;
      height: 55px;
      transform: translateX(-50%);
      cursor: pointer;
      @include to-tablet-sm {
        & {
          width: 48px;
          height: 48px;
        }
      }
      @include iphone-xs{
        margin-top: 32px;
      }
      animation: move 1s infinite alternate;
    }
  }
  @keyframes move {
  0% { top: 80%}
  50%   { top: 82% }
  100% {top:80%}
}
  #parterships-container {
    background: #122738;
    padding: 3rem;
    @include to-tablet-sm {
      & {
        padding: 1rem;
      }
    }
    .gatePlusCard {
      width: 100%;
      height: 100%;
      box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
      border-radius: 50px;
      margin-top: 0;
      margin-bottom: 0;
      & img {
        width: 150px;
        height: 150px;
        margin: auto;
        border-radius: 15px;
      }
    }

    .react-multiple-carousel__arrow {
      background: transparent;
      z-index: 9 !important;
      &.react-multiple-carousel__arrow--right {
        right: -15px !important;
        &::before {
          content: url("../../icons/svgs/carousel-arrow-right-white.svg");
        }
      }
      &.react-multiple-carousel__arrow--left {
        left: -15px !important;
      }
      &::before {
        content: url("../../icons/svgs/carousel-arrow-left-white.svg");
      }
    }
  }
  .about-us {
    background: #ffffff;
  }
  .about-img {
    width: 50%;
    border-radius: 8px;
    @include to-mobile-sm {
      & {
        width: 100%;
      }
    }
  }

  .questions-answers {
    background-color: #fafbfb;

    .question-answer-card {
      padding: 3rem 2rem;
      justify-content: center;
      align-items: center;
      @include to-tablet-sm {
        & {
          padding: 0.5rem 1rem;
        }
      }
     
      .card-image {
        flex-grow: 0;
        width: 50%;
        @include from-tablet-sm {
          &.translate-positive {
            transform: translateX(50px);
          }
          &.translate-negative {
            transform: translateX(-50px);
          }
        }
        @include to-tablet-sm {
          & {
            width: 90%;
            border-radius: 20px;
            margin: auto;
          }
        }
      }
      .card-body {
        z-index: 9;
        flex-grow: 0;
        box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
        background: #fff;
        text-align: center;
        width: 526px;
        border-radius: 8px;
        padding: 2rem;
        height: max-content;
        @include from-tablet-sm {
          &.translate-positive {
            transform: translateX(50px);
          }
          &.translate-negative {
            transform: translateX(-50px);
          }
        }
          @include to-tablet-lg {
          & {
           
            padding: 1.5rem;
          }
        }
        @include to-tablet-sm {
          & {
            width: 95%;
            margin: auto;
            transform: translateY(-100px);
            padding: 1.5rem;
          }
        }
        @include iphone-xs{
          width:80%
        }
      }
    }
  }
  .join-us-on-apps {
    background: #fafbfb;
    .mobile-img {
      width: 50%;
      height: 400px;
    }
    .stores-img {
      width: 95%;
    }
  }
  .landing-stats {
    background: #eef9ff;
    color: #006a9f;
    @include iphone-xs{
      .star-3{
        width:30%;
      }
    }
    .stats {
      font-family: rubik;
      font-size: 45px;
      font-weight: 600;
      @include to-tablet-lg {
        & {
          font-size: 35px;
        }
      }
      @include to-tablet-sm {
        & {
          font-size: 30px;
        }
      }
    
    }
    .title {
      font-family: poppins;
      font-size: 20px;
      @include to-tablet-sm {
        & {
          font-size: 16px;
        }
      }
    }
  }
  .latest-jobs {
    background-color: #fafbfb;
    .find-more-jobs {
      color: #66ccff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
.latest-job-card {
  box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include to-tablet-sm {
    & {
      padding: 16px 8px;
    }
  }
  .job-img {
    width: 77px;
    height: 80px;
    @include to-tablet-sm {
      & {
        width: 37px;
        height: 40px;
      }
    }
  }
  .job-title {
    color: $primary-color-blue;
    max-height: 46px;
    overflow: hidden;
  }
  .job-address {
    color: #545454;
  }
  .job-from {
    color: #c8c8c8;
  }
  .job-description {
    color: #8d8d8d;
    word-break: break-all;
    max-height: 36px;
    overflow: hidden;
  }
}
.public-home-spacing{
   padding: 30px 72px;
    @include to-desktop-sm {
      & {
        padding: 16px;
      }
    }
    @include to-tablet-sm {
      & {
        padding: 16px;
      }
    }
  }
.what-we-offer {
  background: #eef9ff;
  .services-card {

    background: #fff;
    width: 90%;
    // height: 276px;
    padding: 35px 40px;
    //padding-bottom: 12px;
    border-radius: 16px;
    box-shadow: 0px 1.888198733329773px 15.105589866638184px 0px #0000000d;
    @include from-tablet-sm {
      text-align: start;
    }
    @include to-desktop-lg {
      padding: 15px 20px;
    }
    .title {
      margin: 1rem 0;
      span {
        border-bottom: 2px solid $primary-color-sky;
        padding: 1rem 0;
      }
    }
  }
  .react-multiple-carousel__arrow {
    background: transparent;
    z-index: 9 !important;
    &.react-multiple-carousel__arrow--right {
      right: -15px !important;
      &::before {
        content: url("../../icons/svgs/carousel-arrow-right.svg");
      }
    }
    &.react-multiple-carousel__arrow--left {
      left: -15px !important;

      &::before {
        content: url("../../icons/svgs/carousel-arrow-left.svg");
      }
    }
  }
}
