.companies-container {
    padding: 40px 72px;
    background-color: #fafbfb;

    .filter-container {
        border-radius: 8px;
        padding: 24px 56px;
        margin-bottom: 56px;
        background-color: #fff;
        box-shadow: 0px 1.8882px 15.1056px rgba(0, 0, 0, 0.05);

        h5 {
            color: $primary-color-dark-blue;
        }

        .input-container {
            width: 50%;
        }

    }

    .companies-wrapper {
        display: grid;
        gap: 32px 50px;
        //grid-template-columns: auto auto auto;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        .company-card {
            border-radius: 8px;
            overflow: hidden;

            .blueBg {
                background-color: #122738;
                width: 100%;
                height: 76px;
            }

            .card-body {
                background-color: #fff;
                padding: 16px;
                position: relative;

                img {
                    width: 72px;
                    height: 72px;
                    border-radius: 8px;
                    position: absolute;
                    top: -36px;
                    object-fit: cover;
                }

                h3 {
                    color: #006A9F;
                }

                p {
                    color: #C8C8C8;
                    margin-bottom: 0;
                }
            }
        }
    }


    @include to-tablet-lg {
        padding: 32px 50px;

        .filter-container {
            padding: 16px 20px;
            margin-bottom: 24px;
        }

        .companies-wrapper {
            //grid-template-columns: auto auto;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @include to-tablet-sm {
        padding: 24px 20px;

        .filter-container {
            flex-direction: column;
            gap: 16px;
            padding: 16px 20px;

            .input-container {
                width: 100%;
            }
        }

        .companies-wrapper {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.company-profile {
    display: flex;
    justify-content: space-between;
    padding: 60px 72px;
    gap: 64px;
    width: 100%;

    .card-title {
        color: #122738;
        margin: 0 0 24px !important;
    }

    .main-container {
        width: 60%;

    }

    .main-card {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1.89px 15.11px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;

        .blueBg {
            background-color: #122738;
            width: 100%;
            height: 96px;
        }

        .card-body {
            padding: 24px 56px 32px;
            position: relative;

            img {
                width: 80px;
                height: 80px;
                border-radius: 8px;
                position: absolute;
                top: -50px;
                object-fit: cover;
            }

            h3 {
                color: #006A9F;
                margin-bottom: 24px;
            }

            p {
                span {
                    margin-inline: 8px;
                    font-weight: 600 !important;
                }
            }
        }
    }

    .general-info-card {
        margin-block: 24px;
        padding: 40px 56px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1.89px 15.11px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;

        .general-data {
            margin-bottom: 24px;

            .item {
                background-color: #EAEAEA;
                padding: 4px 16px;
                border-radius: 8px;
                color: #122738;
                width: fit-content;
            }
        }
    }

    /* ******************* */

    .jobs-container {
        /* flex-grow: 1; */
        min-width: 350px;
        width: 350px;

        height: fit-content;
        background-color: #fff;
        padding: 40px 24px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1.89px 15.11px 0 rgba(0, 0, 0, 0.05);

        .see-more {
            color: #66CCFF;
            text-decoration: underline;
            margin-top: 16px;
            width: fit-content;

            &:hover {
                color: #006a9f;
            }
        }

    }

    @include to-desktop-sm {
        gap: 30px;
    }

    @include to-tablet-lg {
        padding: 32px 0;
        flex-direction: column;
        gap: 0;

        .main-container {
            width: 100%;

            .main-card {
                border-radius: 0;

                .card-body {
                    padding: 16px 20px;
                }
            }

            .general-info-card {
                margin: 16px 20px;
                padding: 24px 16px;
            }
        }

        .jobs-container {
            margin: 0 20px;
            min-width: unset;
            width: unset;
            padding: 24px 16px;
        }
    }

}