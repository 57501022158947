.steplist {
	border-top: 2px solid $grey-ea;
	display: inline-flex;
	list-style-type: none;
	gap: 7em;
	margin: 0;
	padding: 0;
	width: 100%;
	justify-content: space-between;
	&::after,
	&::before {
		box-sizing: border-box;
	}
	li {
		color: $grey-ea;
		cursor: pointer;
		padding: calc(5px + var(--circle-radius)) 0 0;
		margin: 0;
		position: relative;
		text-align: center;
		&::before {
			background-color: $grey-ea;
			border-radius: 50%;
			content: "";
			position: absolute;
			height: 20px;
			width: 20px;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all 0.3s ease;
			position: absolute;
			z-index: 99;
		}
		&:first-child {
			&::after {
				background-color: white;
				content: "";
				position: absolute;
				height: 2px;
				width: 100%;
				top: -2px;
				left: calc(-50% - var(--circle-radius));
			}
		}
	}
	& .active {
		color: #20c997;

		&::before {
			// background-color: #20c997;
			background-image: url("../../icons/svgs/active-circle.svg");
			position: absolute;
			z-index: 99;
		}
	}
	&:last-child {
		&:after {
			background-color: white;
			content: "";
			position: absolute;
			height: 2px;
			width: 100%;
			top: -2px;
			left: calc(50% + var(--circle-radius));
		}
	}
	.circle-big {
		--circle-radius: 12px;
	}
	@include to-mobile-sm {
		& {
			gap : 0
		}
	}
}
