body {
	margin: 0;
	font-family: "Rubik", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: url('/public/images/pattern.png');
	background-size: auto;
	background-attachment: fixed;
	position: relative;
	padding-right: 0;
}
 html {
	scrollbar-width: none;
 	overflow: scroll;
 	overflow-x: hidden;
 }
 ::-webkit-scrollbar {
 	width: 0;
 	background: transparent;
 }	
 
 .mostPopularPackage{
	background-image: url('/public/images/spiderBg.png') !important;
}